import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "utilityLinks", "address", "phone", "sms", "variation", "siteBusinessType", "appointmentCta", "yextMultiLocationdata", "showContactInfoInFooter", "yextContactInformationData", "socialLinks", "burgerToggle"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './TopNav.style';
import Anchor from '../../../atoms/Anchor';
import List from '../../List';
import ListItem from '../../List/ListItem';
import { BUSINESS_TYPES } from '../../../../constants/globalConstants';
import { getAddressURL, getCompleteAddress, convertPhoneFormat } from '../../../../utils/utilityFunction';
import { locationLabels } from '../../../../constants/labels/en';
import analyticsConstants from '../../../../constants/analyticsConstants';
import LocationIcon from '../../../../static/images/icons/Location';
import PhoneIcon from '../../../../static/images/icons/Phone';
import SmsIcon from '../../../../static/images/icons/Comment';
import SocialIcons from '../../SocialIcons';
var TopNav = function TopNav(_ref) {
  var className = _ref.className,
    utilityLinks = _ref.utilityLinks,
    address = _ref.address,
    phone = _ref.phone,
    sms = _ref.sms,
    variation = _ref.variation,
    siteBusinessType = _ref.siteBusinessType,
    appointmentCta = _ref.appointmentCta,
    yextMultiLocationdata = _ref.yextMultiLocationdata,
    showContactInfoInFooter = _ref.showContactInfoInFooter,
    yextContactInformationData = _ref.yextContactInformationData,
    socialLinks = _ref.socialLinks,
    burgerToggle = _ref.burgerToggle,
    others = _objectWithoutProperties(_ref, _excluded);
  var isNvaCorporate = siteBusinessType === BUSINESS_TYPES.CORPORATE;
  var GMBUrl = yextContactInformationData && yextContactInformationData.c_googleMyBusinessCID && "https://maps.google.com/maps?cid=".concat(yextContactInformationData.c_googleMyBusinessCID);
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  var headerAnalytics = analyticsConstants.globalHeader;
  return __jsx("div", {
    className: className
  }, __jsx("div", {
    className: "nav-contacts"
  }, __jsx(List, {
    className: "navbar-nav"
  }, (!showContactInfoInFooter || showContactInfoInFooter == null) && phone && __jsx(ListItem, {
    className: 'nav-phone'
  }, variation === 'logo-center' && __jsx(PhoneIcon, null), __jsx(Anchor, {
    title: "".concat(locationLabels.phone, " ").concat(convertPhoneFormat(phone)),
    to: "tel:".concat(convertPhoneFormat(phone)),
    className: "phone-link",
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    "data-analytics-variation": variation
  }, __jsx("div", {
    className: "heading_five contact-text base-color"
  }, locationLabels.phone, ' ', __jsx("span", null, convertPhoneFormat(phone))))), (!showContactInfoInFooter || showContactInfoInFooter == null) && sms && __jsx(ListItem, {
    className: 'nav-sms'
  }, variation === 'logo-center' && __jsx(SmsIcon, null), __jsx(Anchor, {
    title: "".concat(locationLabels.sms, " ").concat(convertPhoneFormat(sms)),
    to: "sms: ".concat(convertPhoneFormat(sms)),
    className: "phone-link",
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    "data-analytics-variation": variation
  }, __jsx("div", {
    className: "heading_five contact-text base-color"
  }, locationLabels.sms, " ", __jsx("span", null, convertPhoneFormat(sms))))), (!showContactInfoInFooter || showContactInfoInFooter == null) && !isNvaCorporate && addressString && addressString.trim().length > 1 && __jsx(ListItem, {
    className: "nav-address"
  }, variation === 'logo-center' && __jsx(LocationIcon, null), __jsx(Anchor, {
    title: addressString,
    ctaBehavior: "Open in a new page",
    to: GMBUrl || addressStringURL,
    className: "address-link",
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_ADDRESS,
    "data-analytics-variation": variation
  }, __jsx("div", {
    className: "heading_five contact-text base-color"
  }, __jsx("span", {
    className: "address-label"
  }, locationLabels.addressCamelCase), ' ' + addressString))))), __jsx("div", {
    className: "nav-utility"
  }, __jsx(List, {
    className: "navbar-nav nav-utility-wrap"
  }, utilityLinks && utilityLinks.map(function (utilityLink, index) {
    return __jsx(ListItem, {
      className: "nav-utility-link",
      key: utilityLink.sys.id
    }, __jsx("div", {
      className: "heading_five"
    }, __jsx(Anchor, {
      handleLinkClick: burgerToggle,
      title: utilityLink.label,
      "aria-label": utilityLink.ariaLabel,
      to: utilityLink.url,
      hyperlinkType: utilityLink.hyperlinkType,
      ctaBehavior: utilityLink.behavior,
      "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
      "data-analytics-value": utilityLink && utilityLink.dataAnalyticsValue ? utilityLink.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_UTILITIES,
      "data-analytics-variation": variation
    }, utilityLink.label)));
  }), utilityLinks && isNvaCorporate && appointmentCta && __jsx(ListItem, {
    className: "nav-utility-link mobile-only"
  }, __jsx("div", {
    className: "heading_five"
  }, __jsx(Anchor, {
    handleLinkClick: burgerToggle,
    title: appointmentCta.label,
    "aria-label": appointmentCta.ariaLabel,
    to: appointmentCta.url,
    hyperlinkType: appointmentCta.hyperlinkType,
    ctaBehavior: appointmentCta.behavior,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": appointmentCta.dataAnalyticsValue || headerAnalytics.analyticsValue.GLOBAL_NAV_UTILITIES,
    "data-analytics-variation": variation
  }, appointmentCta.label)))), socialLinks && __jsx("div", {
    className: "header-social-icons"
  }, __jsx(SocialIcons, {
    locationName: 'locationName',
    dataAnalyticsSocialLinks: 'dataAnalyticsSocialLinks',
    socialIconsData: socialLinks
  }))));
};
TopNav.defaultProps = {
  className: '',
  phone: '',
  siteBusinessType: ''
};
export default styled(TopNav).withConfig({
  componentId: "sc-j6ll8x-0"
})(["", ";"], styles);
export { TopNav as TopNavVanilla };